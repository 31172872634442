import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
} from 'firebase/auth';
import FacebookLogo from '../../../assets/svg/facebook-f-logo.svg';
import GoogleLogo from '../../../assets/svg/google-g-logo-color.svg';
import { EVENT_NAMES, sendEvent } from '../../../utils/analytics/analytics';
import getFirebaseApp from '../../../utils/firebase';
import { colors } from '../../../utils/styleguide';
import GhostButton from './GhostButton';
import SocialLoginButton from './SocialLoginButton';

const providers = {
  Google: {
    logo: <GoogleLogo height={24} width={24} title="Google" />,
    create: () => new GoogleAuthProvider(),
    scopes: ['profile', 'email'],
  },
  Facebook: {
    logo: <FacebookLogo height={24} width={24} title="Facebook" />,
    create: () => new FacebookAuthProvider(),
    scopes: ['public_profile', 'email'],
  },
};

export type ProviderName = keyof typeof providers;

type Props = {
  providerName: ProviderName;
  label?: string;
  emailSuggestion?: string;
  ariaLabel?: string;
  className?: string;
  variant?: 'detail';
};

const ProviderButton = ({
  providerName,
  label,
  emailSuggestion,
  ariaLabel,
  className,
  variant,
}: Props) => {
  const provider = providers[providerName];
  if (!provider) {
    return <></>;
  }

  const login = async () => {
    sendEvent({
      name: EVENT_NAMES.buttonClick,
      properties: {
        name: `login provider - ${providerName}`,
      },
    });
    const authProvider = provider.create();
    provider.scopes?.length && provider.scopes.forEach((s) => authProvider.addScope(s));
    localStorage.setItem('@Auth:checkRedirectResult', 'true');
    emailSuggestion && authProvider.setCustomParameters({ login_hint: emailSuggestion });
    const firebaseApp = await getFirebaseApp();
    await signInWithRedirect(getAuth(firebaseApp), authProvider);
  };

  if (variant === 'detail') {
    return (
      <GhostButton
        onClick={login}
        label={providerName}
        hideUnderline
        color={colors.info}
        textVariant={'detail'}
      ></GhostButton>
    );
  }

  return (
    <SocialLoginButton
      label={label || `Continue with ${providerName}`}
      logo={provider.logo}
      onClick={login}
      ariaLabel={ariaLabel}
      className={className}
    />
  );
};

export default ProviderButton;
